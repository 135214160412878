@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "California", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #394b5f;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: "CheddarGothicSans";
  font-weight: 900;
}


.title-color {
  color: #fff;
}

@font-face {
  font-family: "California";
  src: local("California"),
    url("./fonts/California-Type-Foundry-Oceanwide-Semibold.woff")
      format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CheddarGothicSans";
  src: local("CheddarGothicSans"),
    url("./fonts/CheddarGothicSansTwo-Light.woff") format("truetype");
  font-weight: light;
}

.california {
  font-family: "California";
}

.cheddarGothic {
  font-family: "CheddarGothicSans";
}

.MuiTypography-root {
  color: white;
}

.MuiButton-label {
  color: #cb7732;
  font-family: "California";
  text-transform: none !important;
  font-weight: bold;
}

.MuiButton-containedPrimary {
  background-color: white !important;
}

.MuiButton-iconSizeMedium {
  display: none !important;
}

.MuiList-root {
  background-color: white !important;
}
.MuiListItem-root {
  box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}

.footer-logo {
  color: #c13540;
  display: inline-block;
  font-size: 45px;
  line-height: 36px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  letter-spacing: -1px;
  max-width: 100px;
  font-weight: bold;
  font-family: "America";
  text-align: center;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
